import { useIntl } from 'react-intl';
import { useDeviceType } from '@packages/shared/src/hooks/useDeviceType/useDeviceType';
import { useSearchParams } from 'next/navigation';
import type { GTMEventGlycerinDisplayNewsletterConfirm } from '@packages/tracking/src/types/events';
import { useTracking } from '@packages/tracking/src/hooks/useTracking/useTracking';
import { useConfig } from '@packages/shared/src/hooks/useConfig/useConfig';
import { useDynamicYieldTracking } from '../../../hooks/useDynamicYieldTracking';
import { SuccessPage } from '../SuccessPage';
import { smallBasicContainer } from '../styles';
import { pagesMessages } from './helpers/messages';
import IconNlSubscribe3Successful from './animated/IconNlSubscribe3Successful';
import { Sovendus, Userwerk } from '../../ExternalScript/scripts';
import type { NewsletterSubscribePageProps } from '../types';
import { rot47 } from '../../../utils';

export const Page3Success = ({
  headline,
  text,
  buttonLabel,
  buttonLabelMobile,
  areaKey,
}: NewsletterSubscribePageProps) => {
  const intl = useIntl();
  const { isMobile } = useDeviceType();
  const {
    sovendus: { enabled: sovendusEnabled },
    forms: {
      apiAgnitasUrl: {
        values: { useDOI },
      },
    },
  } = useConfig();

  const customButtonLabel = isMobile ? buttonLabelMobile : buttonLabel;
  const finalButtonLabel = customButtonLabel || intl.formatMessage(pagesMessages.page3.subText);
  const query = Object.fromEntries(useSearchParams()?.entries() ?? []);
  const { token } = query;
  const dispatchGTMEvent = useTracking();
  const email = token ? rot47(decodeURIComponent(token.toString())) : '';

  useDynamicYieldTracking('newsletter-subscription-v1', email, !useDOI);

  if (token && areaKey) {
    dispatchGTMEvent<GTMEventGlycerinDisplayNewsletterConfirm>({
      event: 'DisplayNewsletterConfirm',
      DisplayNewsletterConfirmData: {
        email,
        custom: { areaKey },
      },
    });
  }

  return (
    <>
      <SuccessPage
        icon={<IconNlSubscribe3Successful sx={{ fontSize: '8rem', mb: 3 }} />}
        headline={headline || intl.formatMessage(pagesMessages.page3.headline)}
        description={text || intl.formatMessage(pagesMessages.page3.description)}
        button={{ text: intl.formatMessage(pagesMessages.page3.button), primary: true }}
        subtext={finalButtonLabel}
        sx={smallBasicContainer}
      />
      {sovendusEnabled ? <Sovendus type="newsletterSuccess" /> : <Userwerk />}
    </>
  );
};
