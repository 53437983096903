import { usePathname, useSearchParams } from 'next/navigation';
import { useI18n } from '@packages/shared/src/hooks/useI18n/useI18n';
import { useConfig } from '@packages/shared/src/hooks/useConfig/useConfig';
import { useSnackbar } from '@packages/shared/src/components/Notification/SnackbarContext';
import { useIntl } from 'react-intl';
import { useTracking } from '@packages/tracking/src/hooks/useTracking/useTracking';
import type { GTMEventGlycerinDisplayError } from '@packages/tracking/src/types/events';
import { GlycerinErrorSeverity } from '@packages/tracking/src/types/enums';
import { rot47 } from '../../../../utils';
import type { FormValues } from '../types';
import { useSubscribeNewsletterGqlMutation } from '../../helpers/useSubscribeNewsletterGqlMutation';
import { generalFormMessages } from '../../messages';

export const useSubmitNlSubscribeGql = (areaKey: string, setPage: (page: number) => void) => {
  const subscribeNewsletterGql = useSubscribeNewsletterGqlMutation();
  const {
    forms: {
      apiAgnitasUrl: {
        values: { useDOI },
      },
    },
  } = useConfig();
  const pathname = usePathname();
  const query = Object.fromEntries(useSearchParams()?.entries() ?? []);
  const { language, localizeUrl } = useI18n();
  const dispatchGTMEvent = useTracking();
  const { addToast } = useSnackbar();
  const { formatMessage } = useIntl();
  return (formData: FormValues) =>
    subscribeNewsletterGql({ formData, areaKey, contextUri: pathname || '/' })
      .then(({ data: resultData }) => {
        if (
          resultData?.subscribeNewsletterRecipient &&
          'errors' in resultData.subscribeNewsletterRecipient &&
          resultData.subscribeNewsletterRecipient.errors &&
          resultData.subscribeNewsletterRecipient.errors.length > 0
        ) {
          throw new Error(formatMessage(generalFormMessages.errorApi));
        }
        if (
          resultData?.subscribeNewsletterRecipient &&
          'alreadySubscribed' in resultData.subscribeNewsletterRecipient &&
          resultData?.subscribeNewsletterRecipient?.alreadySubscribed
        ) {
          // go to Page 4 if user is already subscribed
          setPage(4);
        } else {
          // go to Page 2 if Double Opt-In is used, otherwise to Page 3
          setPage(useDOI ? 2 : 3);
        }

        // add email as token to 'page 3' or 'SuccessPage' (Tenants without DOI) to be able to track e-mail-address
        if (typeof window !== 'undefined') {
          const { email } = formData;
          const token = email && encodeURIComponent(rot47(email));
          const tmpUrl = `${pathname}?${new URLSearchParams({
            ...query,
            token,
          })}`;
          const routerPushUrl = localizeUrl(tmpUrl, language);
          window.history.pushState({}, '', routerPushUrl);
        }

        dispatchGTMEvent({
          event: 'ga_event',
          eventCategory: 'User Interactions',
          eventLabel: 'newsletter',
          eventAction: 'subscribe',
        });
      })
      .catch((error: string) => {
        addToast({ message: error.toString(), severity: 'error', duration: 2500 });
        dispatchGTMEvent<GTMEventGlycerinDisplayError>({
          event: 'DisplayError',
          DisplayErrorData: {
            category: 'newsletter-subscribe',
            label: 'registration',
            detail: 'error',
            errors: [
              {
                errorType: 'formValidation',
                message: error.toString(),
                severity: GlycerinErrorSeverity.WARNING,
                source: 'newsletter registration form',
              },
            ],
          },
        });
      });
};
