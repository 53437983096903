import { useIntl } from 'react-intl';
import type { GTMEventGlycerinDisplayError } from '@packages/tracking/src/types/events';
import { GlycerinErrorSeverity } from '@packages/tracking/src/types/enums';
import { useTracking } from '@packages/tracking/src/hooks/useTracking/useTracking';
import { useConfig } from '@packages/shared/src/hooks/useConfig/useConfig';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { useI18n } from '@packages/shared/src/hooks/useI18n/useI18n';
import type { UseFormSetError } from 'react-hook-form/dist/types/form';
import { useSnackbar } from '@packages/shared/src/components/Notification/SnackbarContext';
import { rot47 } from '../../../../utils';
import { submitMessage } from '../../GeneralForm/helpers/handleSubmit';
import type { FormValues, SetErrorType } from '../types';
import { useFormDeviceType } from '../../GeneralForm/helpers/useFormDeviceType';
import { convertFormDataToPostData } from './settings';

export const useSubmitNlSubscribeRest = (
  setError: UseFormSetError<FormValues>,
  areaKey: string,
  setPage: (page: number) => void,
) => {
  const { formatMessage } = useIntl();
  const formDeviceType = useFormDeviceType();
  const {
    forms: {
      apiAgnitasUrl: {
        values: { useDOI },
      },
    },
  } = useConfig();
  const pathname = usePathname();
  const query = Object.fromEntries(useSearchParams()?.entries() ?? []);
  const { language, localizeUrl } = useI18n();
  const router = useRouter();
  const dispatchGTMEvent = useTracking();
  const { addToast } = useSnackbar();
  return (formData: FormValues) =>
    submitMessage<SetErrorType>({
      endpoint: 'subscribe',
      formatMessage,
      language,
      formDeviceType,
      setError,
      convertedFormNLData: convertFormDataToPostData(formData, areaKey),
      onSuccess: (resultType: string) => {
        if (resultType === 'ALREADY_SUBSCRIBED') {
          // go to Page 4 if user is already subscribed
          setPage(4);
        } else {
          // go to Page 2 if Double Opt-In is used, otherwise to Page 3
          setPage(useDOI ? 2 : 3);
        }

        // add email as token to 'page 3' or 'SuccessPage' (Tenants without DOI) to be able to track e-mail-address
        const { email } = formData;
        const token = email && encodeURIComponent(rot47(email));
        const tmpUrl = `${pathname}?${new URLSearchParams({
          ...query,
          token,
        })}`;
        const routerPushUrl = localizeUrl(tmpUrl, language);
        router.replace(routerPushUrl);

        dispatchGTMEvent({
          event: 'ga_event',
          eventCategory: 'User Interactions',
          eventLabel: 'newsletter',
          eventAction: 'subscribe',
        });
      },
      onError: (errorMsg: string) => {
        addToast({ message: errorMsg, severity: 'error', duration: 2500 });
        dispatchGTMEvent<GTMEventGlycerinDisplayError>({
          event: 'DisplayError',
          DisplayErrorData: {
            category: 'newsletter-subscribe',
            label: 'registration',
            detail: 'error',
            errors: [
              {
                errorType: 'formValidation',
                message: errorMsg,
                severity: GlycerinErrorSeverity.WARNING,
                source: 'newsletter registration form',
              },
            ],
          },
        });
      },
    });
};
