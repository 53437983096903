import { useIntl } from 'react-intl';
import { Box } from '@packages/shared';
import { useDeviceType } from '@packages/shared/src/hooks/useDeviceType/useDeviceType';
import { searchParamsToObject } from '@packages/shared/src/utils/searchParamsToObject/searchParamsToObject';
import { useSearchParams } from 'next/navigation';
import config from '@packages/config';
import { rot47 } from '../../../utils';
import { useDynamicYieldTracking } from '../../../hooks/useDynamicYieldTracking';
import { SuccessPage } from '../SuccessPage';
import { smallBasicContainer } from '../styles';
import { pagesMessages } from './helpers/messages';
import IconNlSubscribe2Doi from './animated/IconNlSubscribe2Doi';
import type { NewsletterSubscribePageProps } from '../types';

export const Page2Doi = ({
  headline,
  text,
  buttonLabel,
  buttonLabelMobile,
}: NewsletterSubscribePageProps) => {
  const intl = useIntl();
  const { isMobile } = useDeviceType();

  const searchParams = useSearchParams();
  const query = searchParamsToObject(searchParams);
  const { token } = query;

  const customButtonLabel = isMobile ? buttonLabelMobile : buttonLabel;
  const finalButtonLabel = customButtonLabel || intl.formatMessage(pagesMessages.page2.subText);
  const email = token ? rot47(decodeURIComponent(token.toString())) : '';

  useDynamicYieldTracking(
    'newsletter-subscription-v1',
    email,
    config.forms.apiAgnitasUrl.values.useDOI,
  );

  return (
    <Box sx={smallBasicContainer}>
      <SuccessPage
        icon={<IconNlSubscribe2Doi sx={{ fontSize: '8rem', mb: 3 }} />}
        headline={headline || intl.formatMessage(pagesMessages.page2.headline)}
        description={text || intl.formatMessage(pagesMessages.page2.description)}
        button={{ text: intl.formatMessage(pagesMessages.page2.button) }}
        subtext={finalButtonLabel}
      />
    </Box>
  );
};
