import { logger } from '@packages/shared/src/utils/logger/logger';
import { useCookies } from '@packages/shared/src/providers/CookieProvider/CookieProvider';
import { dynamicYieldTrackingFetcher } from '../utils/dynamicYield/dynamicYieldTrackingFetcher';

export const useDynamicYieldTracking = (dyType: string, email: string, track: boolean) => {
  const trackingCookieName = `dytrackingalreadysent`;
  const { getCookies, setCookie, destroyCookie } = useCookies();
  const cookies = getCookies();
  const { _dyjsession: dySession, _dyid: dyId, dytrackingalreadysent } = cookies;

  if (track && dySession && dyId && email !== '' && dytrackingalreadysent !== '1') {
    setCookie(null, trackingCookieName, '1', { path: '/', maxAge: 300 });
    dynamicYieldTrackingFetcher(dyType, dyId, dySession, email).catch((error: any) => {
      logger.error(error);
      destroyCookie(null, trackingCookieName, { path: '/' });
    });
  }
};
